import React from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Radio,
} from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ExploreIcon from "@material-ui/icons/Explore";
import MuiAlert from "@material-ui/lab/Alert";

export const QuestionPane = ({ questionInfo, currentIndex, total, onLost }) => {
  const [inputValue, setInputValue] = React.useState("");
  const [radioValue, setRadioValue] = React.useState(0);
  const [showInstructions, setShowInstructions] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);

  const onCheck = () => {
    if (questionInfo.suggestions.length === 0) {
      //single
      if (questionInfo.answer.filter((item, index) => item.toLowerCase() === inputValue.toLowerCase()).length > 0) {
        setShowInstructions(true);
      }
    } else {
      // multiple
      if (
        questionInfo.suggestions[radioValue].toLowerCase() ===
        questionInfo.answer[0].toLowerCase()
      ) {
        setShowInstructions(true);
      }
    }
    setSnackBar(true);
  };

  React.useEffect(() => {
    if (questionInfo.suggestions.length === 0) {
      setInputValue("");
    } else {
      setRadioValue(0);
    }
    setShowInstructions(false);
  }, [questionInfo]);

  return (
    <Box>
      <Box component={Paper} p={2} mb={2}>
        <Box display="flex">
          <Box
            fontWeight="fontWeightBold"
            color={"success.main"}
            fontSize="h4.fontSize"
            display="flex"
            alignItems="flex-end"
            pr={1}
          >
            {currentIndex}
          </Box>
          <Box
            fontWeight={500}
            color={"success.main"}
            fontSize="subtitle1.fontSize"
            display="flex"
            alignItems="flex-end"
            mb={1}
          >
            /{"  "}
            {total} Questions
          </Box>
        </Box>
      </Box>
      {!showInstructions && (
        <Box component={Paper} p={2}>
          <Box
            fontWeight="fontWeightBold"
            color={"primary.main"}
            display="flex"
            alignItems="center"
          >
            <Box component={LiveHelpIcon} mr={1} />
            Question
          </Box>
          <Box fontWeight={500}>{questionInfo.question}</Box>
        </Box>
      )}
      {!showInstructions && (
        <Box component={Paper} p={2} my={2}>
          <Box
            fontWeight="fontWeightBold"
            color="secondary.main"
            display="flex"
            alignItems="center"
          >
            <Box component={QuestionAnswerIcon} mr={1} />
            Answer
          </Box>
          {questionInfo.suggestions.length === 0 && (
            <TextField
              value={inputValue}
              color="secondary"
              onChange={(evt) => setInputValue(evt.target.value)}
            />
          )}
          {questionInfo.suggestions.length > 0 && (
            <RadioGroup
              value={radioValue}
              onChange={(evt) => setRadioValue(Number(evt.target.value))}
            >
              {questionInfo.suggestions.map((suggest, k) => (
                <FormControlLabel
                  key={k}
                  value={k}
                  control={<Radio />}
                  label={suggest}
                />
              ))}
            </RadioGroup>
          )}
          <Box textAlign="right">
            <Button
              variant="contained"
              color="primary"
              disabled={!inputValue || radioValue < 0}
              onClick={onCheck}
            >
              Check!
            </Button>
          </Box>
        </Box>
      )}

      {showInstructions && (
        <Box component={Paper} p={2}>
          <Box
            fontWeight="fontWeightBold"
            color={"secondary.main"}
            display="flex"
            alignItems="center"
          >
            <Box component={ExploreIcon} mr={1} />
            Instructions
          </Box>
          <Box mr={1}>
            {questionInfo.description}
          </Box>
          <ol>
            {questionInfo.instructions.map((instruction, i) => (
              <Box component="li" key={i} p={1}>
                {instruction}
              </Box>
            ))}
          </ol>
          <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={onLost}>
              I’m lost, take me back
            </Button>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackBar}
        autoHideDuration={3000}
        onClose={() => setSnackBar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackBar(false)}
          severity={showInstructions ? "success" : "warning"}
        >
          {showInstructions
            ? "The answer is correct!"
            : "The answer is incorrect!"}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};
