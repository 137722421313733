import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getHonors } from "../../api";
import { splitUniqueName, padZero } from "../../utils";
import { LimitTime, HonorTimeLimit } from "../../variable";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Results(props) {
  const [teamInfos, setTeamInfos] = React.useState([]);

  React.useEffect(() => {
    updateTeamInfos();
  }, []);

  const updateTeamInfos = () => {
    getHonors()
      .then((res) => {
        let closerHonors = res.data.filter((item) => {
          let currentMoment = moment(Date());
          let itemDate = moment.utc(item.time).local().format();
          let duration = moment.duration(currentMoment.diff(itemDate));
          let minutes = duration.asMinutes();
          if (Math.abs(minutes) < HonorTimeLimit) {
            return true;
          }
          return false;
        });
        let infos = {};
        closerHonors.map((honor, _) => {
          const nameInfo = splitUniqueName(honor.name);
          const info = infos[nameInfo.groupId];
          infos = {
            ...infos,
            [nameInfo.groupId]: {
              groupId: nameInfo.groupId,
              memberCount: info ? info.memberCount + 1 : 1,
              completedCount:
                (info ? info.completedCount : 0) +
                (honor.query_count != LimitTime * 60 ? 1 : 0),
              elapsedTime:
                (info ? info.elapsedTime : 0) + honor.query_count ??
                LimitTime * 60,
            },
          };
        });

        let array = Object.keys(infos);
        array = array.map((key, _) => infos[key]);
        array = array.map((item, _) => ({
          ...item,
          elapsedTime: Math.round(item.elapsedTime / item.memberCount),
        }));
        array = array.sort((a, b) => a.elapsedTime - b.elapsedTime);
        setTeamInfos(array);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box component={Paper} p={2} m={3}>
      <Box
        fontWeight="fontWeightBold"
        color={"success.main"}
        fontSize="h4.fontSize"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Team Results
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Team ID</StyledTableCell>
              <StyledTableCell align="right">Number of Members</StyledTableCell>
              <StyledTableCell align="right">
                Number of Completed
              </StyledTableCell>
              <StyledTableCell align="right">
                Average of Completion Time
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamInfos.map((teamInfo, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {teamInfo.groupId ? teamInfo.groupId : "Unknown"}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {teamInfo.memberCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {teamInfo.completedCount}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {padZero(
                    (teamInfo.elapsedTime - (teamInfo.elapsedTime % 60)) / 60,
                    2
                  )}{" "}
                  : {padZero(teamInfo.elapsedTime % 60, 2)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
