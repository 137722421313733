import React from "react";
import { Box, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../redux/actions";
import { WelcomeLeft, WelcomeRight } from "../../components";
import { getTeams, getUser, registerUser, updateUser } from "../../api";
import { makeUniqueName } from "../../utils";
import { LimitTime } from "../../variable";

export default function Welcome(props) {
  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = React.useState(false);
  const [teamInfos, setTeams] = React.useState([]);
  const history = useHistory();
  const errorString = React.useRef("Service Error!");

  React.useEffect(() => {
    let res = getTeams();
    if (res != null && res.length > 0) {
      setTeams(res);
    } else {
      setSnackBar(true);
    }
  }, []);

  const onSubmit = (name, groupId) => {
    if (!name) {
      errorString.current = "Please enter user name";
      setSnackBar(true);
      return;
    }
    if (!groupId) {
      errorString.current = "Please select your team";
      setSnackBar(true);
      return;
    }
    getUser(makeUniqueName(name, groupId))
      .then((res) => {
        updateUser(makeUniqueName(name, groupId), 0, LimitTime * 60)
          .then((res) => {
            if (res.status === 200) {
              dispatch(updateUserInfo(name, groupId));
              history.push("/question");
            } else {
              errorString.current = "Service Error!";
              setSnackBar(true);
            }
          })
          .catch((error) => {
            errorString.current = error;
            setSnackBar(true);
          });
      })
      .catch((error) => {
        registerUser(makeUniqueName(name, groupId), LimitTime * 60)
          .then((res) => {
            if (res.status === 200) {
              dispatch(updateUserInfo(name, groupId));
              history.push("/question");
            } else {
              errorString.current = "Service Error!";
              setSnackBar(true);
            }
          })
          .catch((error) => {
            errorString.current = error;
            setSnackBar(true);
          });
      });
  };

  const welcomeInfos =
    "You will be required to navigate to a destination and locate your missing handset. First you will be required to answer a question about the destination. <br /><br />Then you will be given a series of instruction to locate the phone. <br /><br />Finding and clicking on the phone will lead you to the next destination. <br /><br />To begin, please add your screen name:";

  return (
    <Box m={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={4} xl={3}>
          <WelcomeLeft
            welcomeInfos={welcomeInfos}
            teamInfos={teamInfos}
            onSubmit={onSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={8} xl={9}>
          <WelcomeRight />
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar}
        autoHideDuration={3000}
        onClose={() => setSnackBar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackBar(false)}
          severity={"warning"}
        >
          {errorString.current}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}
