import React from "react";
import { Box, Grid, Snackbar } from "@material-ui/core";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateQuesIndex } from "../../redux/actions";
import { MapViewer, QuestionPane, AlertDlg, Honors } from "../../components";
import { getQuestions, updateUser, getHonors } from "../../api";
import { makeUniqueName, splitUniqueName } from "../../utils";
import { LimitTime, HonorTimeLimit } from "../../variable";

export default function Question(props) {
  const history = useHistory();
  const quesIndex = useSelector((state) => state.quesIndex);
  const userName = useSelector((state) => state.userName);
  const groupId = useSelector((state) => state.groupId);
  const dispatch = useDispatch();

  const [questions, setQuestions] = React.useState(null);
  const [honorInfos, setHonorInfos] = React.useState(null);
  const [reseted, setReseted] = React.useState(false);
  const [alerted, setAlerted] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [serviceError, setServiceError] = React.useState(false);

  const secondRef = React.useRef();

  const messageList = [
    "Great job. Now we are heading to somewhere else.",
    "Great job. Now we are heading to our last location.",
    "You have successfully completed your search for all the lost Android phones - well done!!!\nBut are all your team on track as well? Check your team progress board by clicking on ‘FINISH’, maybe you can help the others in your team to get a good time!",
  ];

  const updateHonors = () => {
    getHonors()
      .then((res) => {
        let closerHonors = res.data.filter((item) => {
          let currentMoment = moment(Date());
          let itemDate = moment.utc(item.time).local().format();
          let duration = moment.duration(currentMoment.diff(itemDate));
          let minutes = duration.asMinutes();
          if (Math.abs(minutes) < HonorTimeLimit) {
            return true;
          }
          return false;
        });
        closerHonors = closerHonors.filter(
          (honor) => splitUniqueName(honor.name).groupId === groupId
        );
        closerHonors = closerHonors.sort((a, b) => b.count - a.count);
        setHonorInfos(closerHonors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (!userName) {
      history.push("/");
      return;
    } else {
      updateQuesIndex(0);
    }

    /*
    let res = getQuestions();
    if (res != null && res.length > 0) {
      setQuestions(res);
    } else {
      setSnackBar(true);
    }
    */
    getQuestions().then((res) => {
       if (res.status === 200) {
         // console.log(res.data);
         setQuestions(res.data);
      } else {
         setSnackBar(true);
      }
    });

    updateHonors();

    let timer = setInterval(() => {
      updateHonors();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onNext = () => {
    if (userName) {
      updateUser(
        makeUniqueName(
          userName,
          groupId
        ),
        quesIndex + 1,
        LimitTime * 60
      ).then((res) => {
        updateHonors();
        setServiceError(false);
        setAlerted(true);
      }).catch((e) => {
        setServiceError(true);
      });
    } else {
      history.push("/");
    }
  };

  const updateElapsedTime = (seconds) => {
    secondRef.current = seconds;
  }

  const updateResult = () => {
    if (userName) {
      updateUser(
        makeUniqueName(
          userName,
          groupId
        ),
        questions.length,
        secondRef.current
      ).then((res) => {
        setServiceError(false);
        history.push("/team_progress");
      }).catch((e) => {
        setServiceError(true);
      });
    } else {
      history.push("/");
    }
  }

  return (
    <Box m={3}>
      {questions && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <QuestionPane
              questionInfo={questions[quesIndex]}
              onLost={() => setReseted(!reseted)}
              currentIndex={quesIndex + 1}
              total={questions.length}
            />
            {honorInfos && (
              <Honors honorInfo={honorInfos} questionCount={questions.length} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={8} xl={9}>
            <MapViewer
              questionInfo={questions[quesIndex]}
              onNext={onNext}
              reset={reseted}
              getElapsedTime={updateElapsedTime}
            />
          </Grid>
        </Grid>
      )}
      {alerted &&
        (quesIndex === questions.length - 1 ? (
          <AlertDlg
            open={alerted}
            onOk={() => {
              setAlerted(false);
              if(quesIndex === questions.length - 1) {
                updateResult();
              }
            }}
            isFinish
            // onRestart={() => {
            //   setAlerted(false);
            //   dispatch(updateQuesIndex(0));
            // }}
            title="" //"You’ve finished – congratulations!"
            content={messageList[quesIndex]}
          />
        ) : (
          <AlertDlg
            open={alerted}
            onOk={() => {
              setAlerted(false);
              dispatch(updateQuesIndex(quesIndex + 1));
            }}
            title="" //"You’ve finished – congratulations!"
            content={messageList[quesIndex]}
          />
        ))}
      <Snackbar
        open={snackBar || serviceError}
        autoHideDuration={3000}
        onClose={() => setSnackBar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackBar(false)}
          severity={"warning"}
        >
          {snackBar ? "No questions!" : "Service Error!"}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}
