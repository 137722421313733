import { UPDATE_QUES_INDEX, UPDATE_USER_INFO } from "./actionTypes";

const initialState = {
  quesIndex: 0,
  userName: "",
  groupId: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_QUES_INDEX: {
      const { quesIndex } = action.payload;
      return {
        ...state,
        quesIndex: quesIndex,
      };
    }
    case UPDATE_USER_INFO: {
      const { userName, groupId } = action.payload;
      return {
        ...state,
        userName: userName,
        groupId: groupId,
      };
    }
    default:
      return state;
  }
}
