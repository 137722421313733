import axios from "axios";
import { SERVICE_URL } from "../config";
import questionData from "./question.json";
import teamData from "./team.json";

export const getQuestions = () => {
  return axios.get("https://street.jaggedhorizons.com/cms/question.json", {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
  //return questionData;
};

export const getTeams = () => {
  return teamData;
};

export const getUser = (userName) => {
  return axios.get(SERVICE_URL + "/" + userName, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
};

export const registerUser = (userName, queryCount) => {
  return axios.post(
    SERVICE_URL,
    {
      name: userName,
      count: 0,
      query_count: queryCount,
    },
    { headers: { "Access-Control-Allow-Origin": "*" } }
  );
};

export const updateUser = (userName, count, queryCount) => {
  return axios.put(
    SERVICE_URL + "/" + userName,
    {
      name: userName,
      count: count,
      query_count: queryCount,
    },
    { headers: { "Access-Control-Allow-Origin": "*" } }
  );
};

export const getHonors = () => {
  return axios.get(SERVICE_URL, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
};
