import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AlertDlg = ({ open, title, content, onOk, isFinish = false, onRestart = null }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={onOk}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onOk} color="primary">
        {isFinish ? "Finish" : "Ok"}
      </Button>
      {onRestart && <Button onClick={onRestart} color="secondary">
        Restart
      </Button>}
    </DialogActions>
  </Dialog>
);
