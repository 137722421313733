import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { AlertDlg } from "../AlertDlg";
import { padZero } from "../../utils";

export const Clock = (props) => {
  const { limittime, getElapsedTime } = props;
  const [seconds, setSeconds] = React.useState(59);
  const [alert, setAlert] = React.useState(false);

  const timer = React.useRef();
  const minutes = React.useRef(limittime - 1);

  const history = useHistory();

  const updateElapsedTime = (secs) => {
    getElapsedTime(secs);
  };

  React.useEffect(() => {
    timer.current = setInterval(() => {
      setSeconds((seconds) => {
        if (seconds - 1 < 0) {
          minutes.current--;
          if (minutes.current < 0) {
            minutes.current = 0;
            setAlert(true);
            clearInterval(timer.current);
            return 0;
          }
          return 59;
        }
        updateElapsedTime(
          (limittime - minutes.current - 1) * 60 + (60 - seconds)
        );
        return seconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <Box>
      <Box
        textAlign={"center"}
        fontSize={"h3.fontSize"}
        color={"secondary.main"}
      >
        {padZero(minutes.current, 2)} :{" "}
        {padZero(seconds, 2)}
      </Box>
      {alert && (
        <AlertDlg
          open={alert}
          onOk={() => {
            setAlert(false);
            history.replace("/team_progress");
          }}
          title="Sorry time is up!"
          content=" Please check how your team has done."
        />
      )}
    </Box>
  );
};
