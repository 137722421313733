import React from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Snackbar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  radioBox: {
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  radioItem: {
    width: "100%",
    overflow: "hidden",
  },
}));

export const WelcomeLeft = ({ welcomeInfos, teamInfos, onSubmit }) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState("");
  const [teamValue, setTeamValue] = React.useState("");
  const [snackBar, setSnackBar] = React.useState(false);

  React.useEffect(() => {
    if (teamInfos && teamInfos.length > 0) {
      setTeamValue(teamInfos[0].team_id);
    }
  }, []);

  const handleChange = (event) => {
    setTeamValue(event.target.value);
  };

  return (
    <Box>
      <Box component={Paper} p={2}>
        <Box
          fontSize="h6.fontSize"
          fontWeight="600"
          color={"secondary.main"}
          display="flex"
          alignItems="center"
        >
          Welcome to your virtual offsite Street View Challenge - find my Pixel
          4.
        </Box>
        <Box
          component="div"
          py={2}
          dangerouslySetInnerHTML={{ __html: welcomeInfos }}
        />
        <Box mb={1}>
          <TextField
            value={inputValue}
            color="secondary"
            onChange={(evt) => setInputValue(evt.target.value)}
          />
        </Box>
        {teamInfos && (
          <FormControl component="fieldset" className={classes.radioBox}>
            <Box component="div" py={2}>
              Next, select the team you have been assigned:
            </Box>
            <RadioGroup
              aria-label="team"
              name="team"
              value={teamValue}
              onChange={handleChange}
            >
              <Grid container>
                {teamInfos.map((teamInfo, index) => (
                  <Grid item xs={6} sm={4} md={3} lg={6} key={index}>
                    <FormControlLabel
                      className={classes.radioItem}
                      value={teamInfo.team_id}
                      control={<Radio />}
                      label={teamInfo.team_id}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
        <Box textAlign="left">
          <Button
            onClick={() => onSubmit(inputValue, teamValue)}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackBar}
        autoHideDuration={3000}
        onClose={() => setSnackBar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackBar(false)}
          severity={"Welcome"}
        >
          Welcome to join!
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};
