import { UPDATE_QUES_INDEX, UPDATE_USER_INFO } from "./actionTypes";

export const updateQuesIndex = (quesIndex) => ({
  type: UPDATE_QUES_INDEX,
  payload: {
    quesIndex,
  },
});

export const updateUserInfo = (userName, groupId) => ({
  type: UPDATE_USER_INFO,
  payload: {
    userName: userName,
    groupId: groupId,
  },
});
