import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import Question from "./question";
import Welcome from "./welcome";
import Results from "./results";
import TeamProgress from "./teamProgress";

export default function AppRouter(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/question" component={Question} />
          <Route exact path="/results" component={Results} />
          <Route exact path="/team_progress" component={TeamProgress} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}
