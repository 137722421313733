import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getHonors } from "../../api";
import { splitUniqueName, padZero } from "../../utils";
import { HonorTimeLimit } from "../../variable";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TeamProgress(props) {
  const groupId = useSelector((state) => state.groupId);
  const [teamInfos, setTeamInfos] = React.useState([]);

  React.useEffect(() => {
    updateTeamInfos();
  }, []);

  const updateTeamInfos = () => {
    getHonors()
      .then((res) => {
        let teamHonors = res.data.filter((item) => {
          let currentMoment = moment(Date());
          let itemDate = moment.utc(item.time).local().format();
          let duration = moment.duration(currentMoment.diff(itemDate));
          let minutes = duration.asMinutes();
          const nameInfo = splitUniqueName(item.name);

          if (
            Math.abs(minutes) < HonorTimeLimit &&
            groupId === nameInfo.groupId
          ) {
            return true;
          }
          return false;
        });
        teamHonors = teamHonors.sort((a, b) => b.count - a.count);
        setTeamInfos(teamHonors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const description = "Here you can see where all your teammates are up to - why not give them a hand if there are any problems. Once you have all finished, come back to the main meeting and we will announce the winning team!!";

  return (
    <Box component={Paper} p={2} m={3}>
      <Box
        fontWeight="fontWeightBold"
        color={"success.main"}
        fontSize="h4.fontSize"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Team Progress
      </Box>
      <Box
        mb={1}
        mx={3}
        fontWeight="fontWeightBold"
        color={"success.main"}
        fontSize="h5.fontSize"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        {description}
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Completed Maps</StyledTableCell>
              <StyledTableCell align="right">Completion Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamInfos.map((teamInfo, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {splitUniqueName(teamInfo.name).userName}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {teamInfo.count} / 3
                </StyledTableCell>
                <StyledTableCell align="right">
                  {padZero(
                    (teamInfo.query_count - (teamInfo.query_count % 60)) / 60,
                    2
                  )}{" "}
                  : {padZero(teamInfo.query_count % 60, 2)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
