import React from "react";
import { Box, Paper, LinearProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { splitUniqueName } from "../../utils";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: `calc(100% - ${theme.spacing(8)}px)`,
    height: theme.spacing(1),
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  percent: {
    width: theme.spacing(8),
  },
}));

export const Honors = ({ honorInfo, questionCount }) => {
  const classes = useStyles();
  return (
    <Box component={Paper} px={1} py={2} my={2}>
      <Box
        fontWeight={600}
        color={"success.main"}
        fontSize="h6.fontSize"
        pr={1}
      >
        TEAM PROGRESS
      </Box>
      {honorInfo.map((honor, i) => (
        <Box key={i} p={1}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box
                width={10}
                fontWeight={500}
                color={"success.main"}
                fontSize="h6.fontSize"
                pr={1}
              >
                {i + 1}
              </Box>
              <Box
                fontWeight={500}
                color={"success.main"}
                fontSize="h6.fontSize"
                px={2}
              >
                {splitUniqueName(honor.name).userName}
              </Box>
            </Box>
          </Box>
          <Box pl={3} py={1} display="flex" alignItems="center">
            <BorderLinearProgress
              variant="determinate"
              value={Math.round((honor.count / questionCount) * 100)}
            />
            <Box
              className={classes.percent}
              fontWeight={500}
              color={"success.main"}
              fontSize="subtitle.fontSize"
              px={1}
            >
              {Math.round((honor.count / questionCount) * 100)} %
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
