import React from "react";
import { GOOGLE_MAP_KEY } from "../../config";
import { Box, useTheme, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  StreetViewPanorama,
} from "@react-google-maps/api";
import { parse } from "../../utils";
import { Clock } from "../Clock";
import logoImage from "../../assets/logo.jpg";
import markerImage from "../../assets/mark.svg";
import { LimitTime } from "../../variable";

const defaultCenter = {
  lat: 37.869085,
  lng: -122.254775,
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: theme.spacing(10),
    width: theme.spacing(35),
    backgroundSize: "contain",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
  },
  clock: {
    height: theme.spacing(8),
    width: theme.spacing(25),
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 100,
  },
}));

export const MapViewer = React.memo(
  (props) => {
    const { questionInfo, onNext, getElapsedTime, reset } = props;
    const classes = useStyles();
    const urlInfo = parse(questionInfo.url);
    const tokenInfo = parse(questionInfo.tokenUrl);
    const theme = useTheme();
    const [visibleMarker, setVisibleMarker] = React.useState(true);

    const panoramaRef = React.useRef();

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAP_KEY,
    });

    const rad = (x) => {
      return (x * Math.PI) / 180;
    };

    const getDistance = (p1, p2) => {
      var R = 6378137; // Earth’s mean radius in meter
      var dLat = rad(p2.lat() - p1.lat());
      var dLong = rad(p2.lng() - p1.lng());
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) *
          Math.cos(rad(p2.lat())) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d; // returns the distance in meter
    };

    const onLoad = (streeeViewPanorama) => {
      panoramaRef.current = streeeViewPanorama;
    };

    const onPositionChanged = () => {
      if (panoramaRef.current) {
        let panorama = panoramaRef.current;
        if (
          getDistance(
            panorama.getPosition(),
            new window.google.maps.LatLng(
              tokenInfo.lat + questionInfo.offset.lat,
              tokenInfo.lng + questionInfo.offset.lng
            )
          ) < 50
        ) {
          // setVisibleMarker(true);
        }
      }
    };

    const renderMap = () => {
      return (
        <Box position={"relative"}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: `${theme.spacing(100)}px`,
              borderRadius: theme.spacing(0.5),
            }}
            center={defaultCenter}
          >
            <StreetViewPanorama
              position={{
                lat: urlInfo.lat,
                lng: urlInfo.lng,
              }}
              pov={{ heading: urlInfo.header, pitch: 0 }}
              zoom={1}
              visible={true}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={onLoad}
              onPositionChanged={onPositionChanged}
            />
            {visibleMarker && (
              <Marker
                position={{
                  lat: tokenInfo.lat + questionInfo.offset.lat,
                  lng: tokenInfo.lng + questionInfo.offset.lng,
                }}
                icon={{
                  url: markerImage,
                  scaledSize: new window.google.maps.Size(20, 40),
                }}
                onClick={onNext}
              />
            )}
          </GoogleMap>
          <CardMedia className={classes.media} image={logoImage} title="" />
          <Box className={classes.clock}>
            <Clock limittime={LimitTime} getElapsedTime={getElapsedTime} />
          </Box>
        </Box>
      );
    };

    if (loadError) {
      return <Box>Map cannot be loaded right now, sorry.</Box>;
    }

    return isLoaded ? renderMap() : <div>Loading...</div>;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.questionInfo.url === nextProps.questionInfo.url &&
      prevProps.reset === nextProps.reset
    );
  }
);
