import { LimitTime } from "../variable";

export const parse = (url) => {
  if (url.startsWith("https://www.google.com/maps/@")) {
    const firstIndex = url.indexOf("@");
    const lastIndex = url.lastIndexOf("/data");

    const paramStr = url.substr(firstIndex + 1, lastIndex - firstIndex - 1);
    const paramList = paramStr.split(",");
    return {
      lat: Number(paramList[0]),
      lng: Number(paramList[1]),
      a: Number(paramList[2].substr(0, paramList[2].length - 1)),
      y: Number(paramList[3].substr(0, paramList[3].length - 1)),
      header: Number(paramList[4].substr(0, paramList[4].length - 1)),
      pitch: Number(paramList[5].substr(0, paramList[5].length - 1)),
    };
  }
  return null;
};

export const makeUniqueName = (name, groupId) => {
  return name + "___" + groupId;
};

export const splitUniqueName = (uniqueName) => {
  const list = uniqueName.split("___");
  return {
    userName: list[0],
    groupId: list.length > 1 ? list[1] : "",
  };
};

export const padZero = (num, size) => {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};
