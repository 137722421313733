import React from "react";
import { CardMedia } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import welcomeImage from "../../assets/welcome.jpg";

const useStyles = makeStyles((theme) => ({
  media: {
		height: theme.spacing(100),
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "top"
  }
}));

export const WelcomeRight = React.memo(() => {
	const classes = useStyles();
  return <CardMedia className={classes.media} image={welcomeImage} title="" />;
});
